import * as React from 'react';
import { useContext, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { JSONEditor } from "@json-editor/json-editor";
import to from 'await-to-js';

import EmptyProject from "./empty-project";
import { APIContext } from "../../utils/api-context";
import {
    AlertBanner,
    CustomButton,
    Dropdown,
    InputField,
    LoadingSpinner,
    Tabs,
    TextArea
} from "@medtronic/pecc-react-component-library-js";
import metadataSchema from "../../config/schemas/project-metadata.schema.json";
import Project from "../../mdt-lib/hdp-client-sdk/orm/project"
import ControlledConfirmModal from "./controlled-confirm-modal";
import { titleCase } from "../../utils/text-utils";

const status_options = require('./form-options/status.json');


function ProjectEditPane({ project, setProject, open, handleOpen, handleClose, eventMediator }) {
    let projectRendered = false;
    const DEFAULT_SUBMIT_BUTTON_LABEL = "Submit";
    const apiManager = useContext(APIContext);
    const user = apiManager.getCurrentUser();
    const isNewProject = project === EmptyProject;
    const [submitButtonLabel, setSubmitButtonLabel] = useState(DEFAULT_SUBMIT_BUTTON_LABEL);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [deactivateConfirmationModalOpen, setDeactivateConfirmationModalOpen] = useState(false);
    const [deactivateProjectDisabled, setDeactivateProjectDisabled] = useState(true);
    const [rulesNotificationMessage, setRulesNotificationMessage] = useState();
    const clearRulesNotificationMessage = () => setRulesNotificationMessage(null);
    const intraHandleClose = () => {
        clearRulesNotificationMessage();
        handleClose();
    }
    const appUsers = getAppropriateProjectMembers(Object.values(apiManager.appUserManager.dataCache));
    const [protoproject, setProtoproject] = useState(project === null ? EmptyProject : project);
    const datasetDropdownOptions = apiManager.datasetManager.dataArray
        .filter(dataset => dataset.id in user.datasetRoles)
        .filter(dataset => !["pending_reader", "pending_writer"].includes(user.datasetRoles[dataset.id].role_name))
        .map(dataset => ({
            optionLabel: dataset.name,
            optionValue: dataset.id,
            groupLabel: "",
        }))

    if (isNewProject) {
        if (project.roster.length === 0) {
            project.roster.push(apiManager.currentUser.id);
        }
    } else {
        let datasetIds = apiManager.getDatasetIdsInProject(project.id);
        console.log("***** Incoming Dataset Ids ******");
        console.log(datasetIds);
        project.data_sets = datasetIds;
    }

    const setRuleResultMessage = ruleResult => {
        setRulesNotificationMessage(`${ruleResult.message}.  ${ruleResult.details}`)
    };
    apiManager.datasetProjectRelationManager.addRuleResultsListener(setRuleResultMessage, "ProjectEditPane");

    function FormTitle() {
        return <DialogTitle>
            <Typography variant="h3" component="h1">
                {isNewProject ?
                    'Create a New Project' :
                    `Edit Project - "${project.name}"`
                }
            </Typography>
        </DialogTitle>
    }

    // Check if all workbenches associated to the project are terminated.
    function updateDeactivateButton(statuses) {
        console.log("Updating deactivate button...");
        let canCloseProject = true;
        for (let k in statuses) {
            if (!((statuses[k] == "unknown/not-found") || (statuses[k] == "terminated"))) {
                canCloseProject = false;
                break;
            }
        };

        if (canCloseProject) {
            console.log("All workbenches terminated. Project deactivation allowed.");
            setDeactivateProjectDisabled(false);
        } else {
            console.log("Active workbenches found. Project deactivation disallowed.");
            setDeactivateProjectDisabled(true);
        }
    }

    // fetch the status of all workbenches associated with the project
    function canCloseProject() {
        let allWorkbenches = apiManager.workbenchManager.dataArray;
        let projectWbInstancesIds = [];
        console.log("Checking if project can be deactivated...");

        for (let wb of allWorkbenches) {
            if (wb.project_id == project.id) {
                projectWbInstancesIds.push(wb.metadata.InstanceId);
            }
        }

        if (projectWbInstancesIds.length > 0) {
            apiManager.workbenchManager.getWorkbenchStatuses(projectWbInstancesIds, updateDeactivateButton);
        } else {
            setDeactivateProjectDisabled(false);
        }
    }

    function FormActions() {
        canCloseProject();

        return (
            <>
                {!isNewProject && <ControlledConfirmModal
                    open={deactivateConfirmationModalOpen}
                    toggleOpenFn={() => setDeactivateConfirmationModalOpen(!deactivateConfirmationModalOpen)}
                    modalTitle="Deactivate this project?"
                    modalDescription={<>
                        <p>Before you proceed with deactivating this project, please ensure that you have saved all
                            artifacts associated with this project.</p>
                        <p>Upon deactivation, all temporary data within the project will be deleted.</p>
                        <p>Are you sure you want to deactivate the project?</p>
                    </>}
                    primaryButtonLabel={<Stack direction="row" spacing={1} alignItems="center">
                        {"Deactivate"}
                        {isSubmitting && <LoadingSpinner spinnerSize="xs" />}
                    </Stack>}
                    primaryButtonHandler={() => {
                        setIsSubmitting(true);
                        closeProject();
                    }}
                    secondaryButtonLabel="Cancel"
                    secondaryButtonHandler={() => setDeactivateConfirmationModalOpen(false)}
                />}
                <DialogActions>
                    <Stack spacing={2} direction="row">
                        {user.privileges.project.canClose && !isNewProject &&
                            <Tooltip
                                title={
                                    <Typography variant="body1">
                                        {deactivateProjectDisabled ? "Cannot close a project with an active workbench." : "Mark the project as inactive. This action cannot be undone."}
                                    </Typography>}
                                placement="top">
                                <Box>
                                    <CustomButton
                                        label="Deactivate Project"
                                        buttonType="outlined"
                                        onClick={() => setDeactivateConfirmationModalOpen(true)}
                                        disabled={deactivateProjectDisabled}
                                    />
                                </Box>
                            </Tooltip>
                        }
                        <CustomButton buttonType="outlined" onClick={intraHandleClose}>Close</CustomButton>
                        <CustomButton id="create-project-submit-button" buttonType="loading" type="submit"
                            form="project-form" label="Submit"
                            isLoading={submitButtonLabel !== DEFAULT_SUBMIT_BUTTON_LABEL} />
                    </Stack>
                </DialogActions>
            </>
        )
    }

    function handleUpdateProject() {
        // TODO: Take out the window reload once getting the project by ID refreshes the project details in the edit pane

        apiManager.projectManager.getProject(project.id)
            .then(updated => {
                // Check if the API response is valid
                if (updated && typeof updated === 'object') {
                    // If users field is undefined, set it to an empty array
                    const users = Array.isArray(updated.users) ? updated.users : [];
                    setProject({ ...updated, users });  // Always update the project with a valid users array
                } else {
                    console.error("Invalid project data received from API");
                }
            })
            .then(() => window.location.reload())
            .catch(e => console.error(e));

        intraHandleClose();
    }

    function handleCreateProject() {
        window.location.reload();
        intraHandleClose();
    }

    function closeProject() {
        const msg = 'Closing project: \'' + project.name + '\' with project-id: ' + project.id
        console.log(msg);
        const updatedProject = new Project();
        updatedProject.setAttributes({ ...project, status: "inactive" });
        apiManager.projectManager.updateProject(updatedProject)
            .then(resp => {
                eventMediator.success();
                handleUpdateProject(resp);
            })
            .catch(e => {
                eventMediator.failure();
                console.warn(e);
            });
    }

    function getAppropriateProjectMembers(appUserArray) {
        const appropriateRoleNames = ['data_scientist', 'developer', 'technician', 'tester'];
        const existingProjectMemberIndex = {};
        const candidateProjectMemberIndex = {};
        const appropriateAppUsers = [];
        for (let project of apiManager.projectManager.dataArray) {
            if (project.users) {
                for (let projectUser of project.users) {
                    existingProjectMemberIndex[projectUser.user.id] = projectUser.user;
                }
            }
        }
        for (let appUser of appUserArray) {
            if (appUser && appUser.roles) {
                for (let applicationRole of appUser.roles) {
                    if (appropriateRoleNames.includes(applicationRole.name) && !existingProjectMemberIndex[appUser.id]) {
                        candidateProjectMemberIndex[appUser.id] = appUser;
                    }
                }
            }
        }
        const existingMembers = Object.values(existingProjectMemberIndex);
        for (let existingMember of existingMembers) {
            appropriateAppUsers.push(existingMember);
        }
        const candidateMembers = Object.values(candidateProjectMemberIndex);
        for (let candidateMember of candidateMembers) {
            appropriateAppUsers.push(candidateMember);
        }
        return appropriateAppUsers;
    }



    function handleChange(event) {
        let value = event.target.value
        if (protoproject.id) {
            let datasets = apiManager.getDatasetIdsInProject(protoproject.id);
            console.log(datasets);
        }
        console.log(event.target.name);
        setProtoproject({ ...protoproject, [event.target.name]: value });
    }

    function projectIsValid(project) {
        const errorMessages = [];
        if (!project.name) {
            errorMessages.push("The project must have a title");
        }
        if (!project.description) {
            errorMessages.push("The project needs a description");
        }
        if (!project.operating_unit_id) {
            errorMessages.push("Please supply an operating unit identification for the project");
        }
        if (!project.cost_center) {
            errorMessages.push("A new project must have a cost center");
        }
        if (!project.data_sets || project.data_sets.length < 1) {
            errorMessages.push("The project must have at least one dataset attached");
        }
        if (errorMessages.length > 0) {
            let errorMessage = "Please fix the following errors before submitting this project: \n\n";
            for (let line of errorMessages) {
                errorMessage += ("    - " + line + "\n");
            }
            alert(errorMessage);
            return false;
        }
        return true;
    }

    function onDatasetsChange(newDatasetsList) {
        let newDatasetId = null;
        const newDatasetArrayIsSubsetOfOld = newDatasetsList.map(el => el.optionValue).every(el => protoproject.data_sets.includes(el));
        if (newDatasetsList.length > 0 && !newDatasetArrayIsSubsetOfOld) {
            newDatasetId = newDatasetsList[newDatasetsList.length - 1].optionValue;
        }
        const rulesFinding = apiManager.datasetProjectRelationManager.rulesManager.runExplicitRuleSet('newDataset',
            { dataset_id: newDatasetId, project_id: protoproject.id });
        console.log("Datasets changing...");
        console.log(rulesFinding);
        if (!rulesFinding.success) {
            // Remove the new dataset from the dataset list
            newDatasetsList.pop();
        }
    }

    async function onSubmit(event) {
        event.preventDefault();
        if (!projectIsValid(protoproject)) {
            return null;
        }
        setIsSubmitting(true);
        if (apiManager.projectManager) {
            if (isNewProject) {
                //
                //create a new Project
                //
                setSubmitButtonLabel("CREATING");
                protoproject.created_by = apiManager.currentUser.id;
                const newProject = new Project();
                newProject.setAttributes(protoproject);
                const [createProjectError, createProjectResult] = await to(apiManager.projectManager.createProject(newProject));
                if (createProjectError) {
                    eventMediator.failure();
                    console.warn("Error encountered in creating project.");
                    console.warn(createProjectError);
                    setSubmitButtonLabel(DEFAULT_SUBMIT_BUTTON_LABEL);
                    alert("There was an error in creating the project.");
                    return null;
                }
                eventMediator.success();
                console.log("Create Project result:");
                console.log(createProjectResult);
                protoproject.id = createProjectResult;
            } else {
                //
                //update existing Project
                //
                setSubmitButtonLabel("Updating...");
                const updatedProject = new Project();
                updatedProject.setAttributes(protoproject);
                const [updateProjectError, updateProjectResult] = await to(apiManager.projectManager.updateProject(updatedProject));
                if (updateProjectError) {
                    eventMediator.failure();
                    console.warn("Error encountered in creating project.");
                    console.warn(updateProjectError);
                    setSubmitButtonLabel(DEFAULT_SUBMIT_BUTTON_LABEL);
                    alert("There was an error in creating the project.");
                    return null;
                }
                eventMediator.success();
                console.log("Update Project result:");
                console.log(updateProjectResult);
            }

            setSubmitButtonLabel("Working...");

            //
            //associate datasets with the project (for new/existing project)
            //
            const [createDatasetRelError, createDatasetRelResult] = await to(apiManager.updateProjectDatasets(protoproject.data_sets, protoproject.id));
            if (createDatasetRelError) {
                console.error("Error encountered when updating dataset <-> project relations");
                console.error(createDatasetRelError);
                setSubmitButtonLabel(DEFAULT_SUBMIT_BUTTON_LABEL);
                alert("There was an error in finishing project creation. \n\n We were unable to create dataset relationship(s).  Please edit the project again to update these dataset relations");
                return null;
            }
            console.log("Creation of dataset <-> project relations successful.");

            //
            //update the project roster
            //

            if (isNewProject) {
                //By default, the user who creates the project is assigned the role of Project Owner (12)
                var member_roles_obj = {};
                member_roles_obj[apiManager.currentUser.id] = [12];
                const [createRosterError, createRosterResult] = await to(apiManager.updateProjectUsersWithRoles(project.roster, project.id, member_roles_obj));
                if (createRosterError) {
                    console.error("Error encountered when assigning project-owner role during project creation");
                    console.error(createRosterResult);
                    setSubmitButtonLabel(DEFAULT_SUBMIT_BUTTON_LABEL);
                    alert("There was an error in finishing project relations creation. \n\n  Please edit the project again to add appropriate team-lead");
                    return null;
                }
            } else {
                const [createRosterError, createRosterResult] = await to(apiManager.updateProjectUsers(project.roster, project.id));
                if (createRosterError) {
                    console.error("Error encountered when updating user <-> project relations");
                    console.error(createRosterResult);
                    setSubmitButtonLabel(DEFAULT_SUBMIT_BUTTON_LABEL);
                    alert("There was an error in finishing project relations updating. \n\n  Please edit the project again to add appropriate users");
                    return null;
                }
            }

            console.log("Creation of dataset <-> project relations successful.");

            setSubmitButtonLabel(DEFAULT_SUBMIT_BUTTON_LABEL);
            if (isNewProject) {
                handleCreateProject();
            } else {
                handleUpdateProject();
            }
        }
    }

    return <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth='lg'
    >
        <FormTitle />
        <DialogContent>
            <form id="project-form" onSubmit={onSubmit}>
                <Stack spacing={1}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <Stack spacing={2}>
                                <InputField
                                    id="project-input-project_id"
                                    label="Project Title"
                                    value={protoproject.name}
                                    onChange={newValue => setProtoproject({ ...protoproject, name: newValue })}
                                    isDisabled={isSubmitting}
                                    isRequired
                                    pattern=".*"
                                    width="100%"
                                    height="50%"
                                />
                                {!isNewProject && <Box>
                                    <Dropdown
                                        id="project-input-status"
                                        label="Project Status"
                                        value={{
                                            optionLabel: titleCase(protoproject.status),
                                            optionValue: protoproject.status,
                                            groupLabel: ""
                                        }}
                                        dropdownOptions={Array.isArray(status_options) ? status_options.filter(opt => opt.optionValue !== 'inactive') : []}
                                        onChange={newValue => setProtoproject({
                                            ...protoproject,
                                            status: newValue.optionValue
                                        })}
                                        disabled={
                                            (Array.isArray(project.workbenches) && 
                                            project.workbenches.filter(wb => wb.status !== "terminated").length > 0) ||
                                            isSubmitting ||
                                            isNewProject ||
                                            true // Yes, this is always disabled for now. The component (and the disabled arguments) remain here for the case when we want to reactivate the component and add more status options to the list.
                                        }
                                    />
                                </Box>}
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <TextArea
                                id='project-input-description'
                                name="description"
                                label="Description"
                                value={protoproject.description}
                                onChange={newValue => setProtoproject({ ...protoproject, description: newValue })}
                                isDisabled={isSubmitting}
                                numberOfRows={5}
                                isRequired
                                width="100%"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ margin: "0 -1rem" }}>
                        <Grid item xs={6}>
                            <Dropdown
                                id="project-input-operating_unit_id"
                                dropdownOptions={(!!apiManager.operatingUnitManager.dataArray && apiManager.operatingUnitManager.dataArray.length > 0) ?
                                    apiManager.operatingUnitManager.dataArray.map(operatingUnit =>
                                    ({
                                        optionLabel: operatingUnit.name,
                                        optionValue: operatingUnit.id,
                                        groupLabel: operatingUnit.name.split("_")[0],
                                    })
                                    ) : {
                                        optionLabel: "Error",
                                        groupLabel: "",
                                    }}
                                onChange={newValue => setProtoproject({ ...protoproject, operating_unit_id: newValue.optionValue })}
                                value={!!protoproject.operating_unit_id ? {
                                    optionLabel: apiManager.operatingUnitManager.dataCache[protoproject.operating_unit_id].name,
                                    optionValue: protoproject.operating_unit_id,
                                    groupLabel: apiManager.operatingUnitManager.dataCache[protoproject.operating_unit_id].name.split("_")[0],
                                } : protoproject.operating_unit_id}
                                label="Operating Unit ID"
                                disabled={isSubmitting}
                                isRequired
                                isGrouped
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                label="Cost Center"
                                onChange={newValue => setProtoproject({ ...protoproject, cost_center: newValue })}
                                value={protoproject.cost_center}
                                isDisabled={isSubmitting}
                                width="100%"
                                pattern=".*"
                                isRequired
                            />
                        </Grid>
                        {user.privileges.project.canAddDataset && <Grid item xs={12}>
                            <Dropdown
                                label="Datasets"
                                dropdownOptions={datasetDropdownOptions}
                                onChange={newValue => {
                                    onDatasetsChange(newValue);
                                    setProtoproject({ ...protoproject, data_sets: newValue.map(subVal => subVal.optionValue) });
                                }}
                                value={protoproject.data_sets.map(dataset => {
                                    const dataset_obj = apiManager.datasetManager.dataCache[dataset];
                                    return (({
                                        optionLabel: dataset_obj.name,
                                        optionValue: dataset_obj.id,
                                        groupLabel: "",
                                    }))
                                })}
                                disabled={isSubmitting}
                                isRequired
                                isMultiselect
                            />
                            {rulesNotificationMessage && <AlertBanner
                                alertMessage={rulesNotificationMessage}
                                alertType="caution"
                                bannerType="notice"
                                onActionClick={() => setRulesNotificationMessage(null)}
                                actionButtonLabel="Dismiss"
                            />}
                        </Grid>}
                    </Grid>
                </Stack>
            </form>
        </DialogContent>
        <FormActions />
    </Dialog>
}

export default ProjectEditPane;
